<template>
  <b-card>

    <b-tabs content-class="pt-1" fill v-model="tabIndex">
      <b-tab title="Booking Information">
        <div v-if="infoPatient != null">
          <BookingReport :item="infoPatient"></BookingReport>
          <button style="float: right; background-color: rgb(111, 104, 205); color: white" class="btn mt-3"
            @click="tabIndex++">
            Next
          </button>
        </div>
      </b-tab>
      <b-tab title="Assign Driver">
        <b-row>
          <b-col cols="12" class="mb-1 mb-md-0">
            <button style="float:right" class="btn btn-success mb-0" @click="openPageddDriver()">
              Add Driver
            </button>
          </b-col>
          <b-col cols="4" class="mb-0">
            <b-form-group>
              <span style="display: block; margin-bottom: calc(0.438rem + 1px)">Filter by driver name</span>
              <b-form-input v-model="driverName" size="sm" type="text" />
            </b-form-group>
          </b-col>
          <b-col cols="4" class="mb-0">
            <b-form-group>
              <span style="display: block; margin-bottom: calc(0.438rem + 1px)">Filter by driver lastname</span>
              <b-form-input v-model="driverLastName" size="sm" type="text" />
            </b-form-group>
          </b-col>
          <b-col cols="4" class="mb-0">
            <b-form-group>
              <span style="display: block; margin-bottom: calc(0.438rem + 1px)">Filter by driver phone</span>
              <b-form-input v-model="driverPhone" size="sm" type="text" />
            </b-form-group>
          </b-col>
          <b-col cols="6" class="mb-2">
            <span style="display: block; margin-bottom: calc(0.438rem + 1px)">Filter by state</span>
            <v-select v-model="selectedState" label="title" :options="listStates"
              @input="getCitiesByState(selectedState.value, '')" />
          </b-col>
          <b-col cols="6" class="mb-2">
            <span style="display: block; margin-bottom: calc(0.438rem + 1px)">Filter by city</span>
            <v-select v-model="selectedCity" label="title" :options="listCities" :disabled="listCities.length == 0" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-2 text-right">
            <button class="btn" @click="filterDrivers()" style="background: rgb(62, 61, 56);color: white;">
              Filter
            </button>
          </b-col>
        </b-row>
        <b-row>
          <table class="table" style="margin-bottom: 50px">
            <thead>
              <tr>
                <th scope="col">Driver Name</th>
                <th scope="col">Driver Phone</th>
                <th scope="col">Cities</th>
                <th scope="col">State</th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(driver, index) in listDrivers" :key="index">
                <td>{{ driver.name }} {{ driver.lastname }}</td>
                <td>{{ driver.phone_number }}</td>
                <td>
                  <span v-if="driver.work_cities[0]">
                    <span v-for="(city, index) in driver.work_cities" :key="index">
                      <span style="
                          color: white;
                          padding: 4px;
                          margin: 5px;
                          border-radius: 9px;
                          background: green;
                        ">{{ city.city.toUpperCase() }}</span>
                    </span>
                  </span>
                </td>
                <td>
                  <span v-if="driver.work_cities[0]">
                    <span style="
                        color: white;
                        padding: 4px;
                        margin: 5px;
                        border-radius: 9px;
                        background: green;
                      ">{{ driver.work_cities[0].state.toUpperCase() }}</span>
                  </span>
                </td>

                <td class="text-center">
                  <button class="btn btn-primary" @click="formSubmitted(driver.driver_id)">
                    Assign
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <br /><br />
        </b-row>

        <button style="float: left; background-color: rgb(111, 104, 205); color: white" class="btn mt-3"
          @click="tabIndex--">
          Previous
        </button>
      </b-tab>
    </b-tabs>

    <b-modal id="modal-error-driver-price" centered size="sm" hide-footer>
      <div style="text-align: center;">
        <span class="mb-2" style="color: red;">Driver price must have a value</span>

        <br>
        <router-link v-if="infoPatient != null" target="_blank" class="urlPagina"
          :to="{ name: 'details-reservation', params: { id: infoPatient.id } }">
          Change driver price here
        </router-link>
      </div>

      <b-col class="col-6 mt-2">
      </b-col>
      <b-col style="display: flex;flex-direction: row;align-content: flex-start;justify-content: space-around;">
        <b-button class="col-6" block @click="$bvModal.hide('modal-error-driver-price')">Close</b-button>
      </b-col>


    </b-modal>

    <b-modal id="modal-error-driver-price" centered size="sm" hide-footer>
      <div style="text-align: center;">
        <span class="mb-2" style="color: red;">Driver price must have a value</span>

        <br>
        <router-link v-if="infoPatient != null" target="_blank" class="urlPagina"
          :to="{ name: 'details-reservation', params: { id: infoPatient.id } }">
          Change driver price here
        </router-link>
      </div>

      <b-col class="col-6 mt-2">
      </b-col>
      <b-col style="display: flex;flex-direction: row;align-content: flex-start;justify-content: space-around;">
        <b-button class="col-6" block @click="$bvModal.hide('modal-error-driver-price')">Close</b-button>
      </b-col>


    </b-modal>
	

  </b-card>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { getMessageError } from "@core/utils/utils";
import BookingReport from "@core/components/bookings/BookingReport";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormDatepicker,
  BFormTimepicker,
  BInputGroupPrepend,
  BInputGroup,
  BTabs,
  BTab,
  BCardText,
  BCard,
  BButton

} from "bootstrap-vue";

export default {
  name: "DetailsAssignDriver",
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    ToastificationContent,
    BookingReport,
    BTabs,
    BTab,
    BCardText,
    BCard,
    BButton
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 2);
    maxDate.setDate(15);
    return {
      dir: "ltr",
      min: minDate,
      max: maxDate,
      // selected: null,
      option: [
        { title: "Wait and return" },
        { title: "Pharmacy stop" },
        { title: "Additional stop" },
      ],

      listDrivers: [],
      filterTypes: [
        {
          title: "State",
          value: "state",
        },
        {
          title: "City",
          value: "city",
        },
      ],
      idDriver: "",
      drivers: {},
      carPlatenuber: "",
      modelVehicle: "",
      iterar: 0,
      infoPatient: null,
      reserva: {
        from: "",
        to: {
          from: "",
        },
      },
      editBooking: false,
      selectedFilterType: {},
      filterText: "",
      fitlerTypeClass: false,
      filterTextClass: false,
      listStates: [],
      listCities: [],
      selectedState: null,
      selectedCity: null,
      tabIndex: 0,
      driverName: "",
      driverLastName: "",
      driverPhone: "",
    };
  },
  watch: {
    idDriver() {
      if (this.idDriver !== "") {
        this.getallDriver();
      }
    },
  },
  methods: {
    filterDrivers() {
      this.getFilteredDrivers();
    },
    formSubmitted(driverId) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .post(
          `admin/panel/booking/${this.infoPatient.id}/edit_driver/${driverId}`
        )
        .then((response) => {
          if (this.editBooking != null) {
            this.$router.push({
              name: "details-reservation",
              params: { id: this.infoPatient.id },
            });
          }

          this.$swal({
            title: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          //this.$refs.assignDriver.reset();
        })
        .catch((error) => {

          let messageError = getMessageError(error);

          if (messageError == 'Driver price must have a value') {
            this.$swal.close();
            this.$bvModal.show("modal-error-driver-price");
          } else {
            this.$swal({
              title: messageError,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }

        });
    },
    getFilteredDrivers() {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      //url filter by city
      let url = `admin/panel/driver/filter_driver_list`;

      let formData = new FormData();
      let availability = 1;
      let status = "Approved";
      let state = this.selectedState != null ? this.selectedState.value : "";
      let city = this.selectedCity != null ? this.selectedCity.value : "";
      let driverName = this.driverName;
      let driverLastName = this.driverLastName;
      let driverPhone = this.driverPhone;

      formData.append("availability", availability);
      formData.append("selectedState", state);
      formData.append("selectedCity", city);
      formData.append("driverName", driverName);
      formData.append("driverLastName", driverLastName);
      formData.append("driverPhone", driverPhone);
      formData.append("status", status);

      this.$http
        .post(url, formData)
        .then((response) => {
          console.log(response)
          this.listDrivers = response.data.data;
          this.$swal.close();
        })
        .catch((res) => console.log(res.data));
    },
    getDrivers() {
      this.$http
        .get(`admin/panel/driver/list?availability=1`)
        .then((response) => {
          this.listDrivers = response.data.data;
        })
        .catch((res) => console.log(res.data));
    },
    getallDriver() {
      // iterar el listado de driver para saber cual fue el seleccionado
      for (this.iterar of this.listDrivers) {
        // evaluar si el seleccionado coincide con uno de la lista
        if (this.idDriver === this.iterar.driver_id) {
          // si coincide guardar el resultado y mostrar la información de los datos del vehiculo
          this.drivers = this.iterar;
          this.modelVehicle = this.drivers.vehicle.model;
          this.carPlatenuber = this.drivers.vehicle.plate_number;
        }
      }
    },
    getPatients() {
      for (let infopaciente of this.$store.getters["Users/usersData"]) {
        if (parseInt(this.$route.params.id) === infopaciente.id) {
          this.infoPatient = infopaciente;
          this.infoPatient.appoinment_datetime =
            this.infoPatient.appoinment_datetime.split(" ")[1];
        }
      }
    },
    async getBookingById(bookingId) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .get(`admin/panel/booking/${bookingId}/info`)
        .then((response) => {
          this.infoPatient = response.data.data;
          this.infoPatient.appoinment_datetime =
            this.infoPatient.appoinment_datetime.split(" ")[1];
          this.reserva.from = JSON.parse(this.infoPatient.from).from;
          this.reserva.to = JSON.parse(this.infoPatient.to).to;
          this.$swal.close();
        })
        .catch((error) => {
          let message = getMessageError(error);
          console.log(message);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getStates(itemSeleted) {
      this.listStates = [];
      this.selectedState = null;
      this.$http
        .get(`states_cities/get_states`)
        .then((response) => {
          this.loadMenuStates(response.data.data, itemSeleted);
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getCitiesByState(state, city) {
      this.listCities = [];
      this.selectedCity = null;
      this.$http
        .get(`states_cities/get_cities_bystate/${state}`)
        .then((response) => {
          this.loadMenuCities(response.data.data, city);
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    loadMenuStates(listData, itemSeleted) {
      this.listStates = [];
      listData.forEach((element) => {
        let data = {
          value: `${element.state_full}`,
          title: `${element.state_full}`,
        };
        this.listStates.push(data);
      });
      this.selectedState = this.listStates.find((x) => x.value == itemSeleted);
    },
    loadMenuCities(listData, itemSeleted) {
      this.listCities = [];
      listData.forEach((element) => {
        let data = {
          value: `${element.city}`,
          title: `${element.city}`,
        };
        this.listCities.push(data);
      });
      this.selectedCity = this.listCities.find((x) => x.value == itemSeleted);
    },
    async loadData() {
      this.editBooking = this.$route.params.edit_booking == null ? false : true;
      let bookingId = this.$route.params.id;
      await this.getBookingById(bookingId);
      this.getStates(null);
    },
    openPageddDriver() {
      const routeData = this.$router.resolve({
        name: "add-driver",
      });
      window.open(routeData.href, "_blank");
    },
  },
  computed: {},
  created() {
  },
  beforeUpdate() { },
  mounted() {
    this.loadData();
  },
};
</script>
